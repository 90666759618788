import React from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { PageLayout } from '@hhs/ui/src/common/PageLayout';
import { Flex } from '@abyss/web/ui/Flex';
import { Button } from '@abyss/web/ui/Button';
import { event } from '@abyss/web/tools/event';
import { config } from '@abyss/web/tools/config';
import { styles } from './Header.styles';

const Header = () => {
  const classes = useStyles(styles)
  const goToDashboard = () => {
    event('BUTTON_CLICK', {
      pageName: 'care unauthenticated landing page- header',
      linkName: 'Sign In',
    });
    window.location.href = config('DASHBOARD');
  };
  return (
    <header className={classes.header}>
      <a className={classes.skipLink} href="#main-content">
        Skip To Main Content
      </a>
      <PageLayout>
        <Flex className={classes.flexHeader}>
          <Flex.Content space-50 space-medium-20>
          <a href="/cares" style={{ float: 'left', marginBottom: '2px' }}>
               <img className={classes.logo}
                src={'/images/hrsa-logo.png'}
                alt="Health Resources and Services Administration Logo"
              />
            </a>
          </Flex.Content>
          <Flex.Content space-500 space-medium-200>            
            <Button className={classes.button} onClick={goToDashboard} variant={'solid'} size={'$md'}>
              Sign In
            </Button>
          </Flex.Content>
        </Flex>
      </PageLayout>
    </header>
  );
};

export { Header };
