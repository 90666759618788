import UHCSansLight from './formats/UHCSans-Light.woff';
import UHCSansLightItalic from './formats/UHCSans-LightItalic.woff';
import UHCSansRegular from './formats/UHCSans-Regular.woff';
import UHCSansItalic from './formats/UHCSans-Italic.woff';
import UHCSansSemiBold from './formats/UHCSans-SemiBold.woff';
import UHCSansBold from './formats/UHCSans-Bold.woff';

// const trimUrl = url => url.replace(/\.\.\//g, '');    // RDR codeql fix
const trimUrl = (url) => {
  return url.split('/').reduce((acc, part) => {
      if (part === '..') {
          if (acc.length > 0) acc.pop();
      } else if (part !== '.' && part !== '') {
          acc.push(part);
      }
      return acc;
  }, []).join('/');
};

export const UHCSans = {
  '@font-face': [
    {
      'font-family': 'UHC Sans',
      'font-weight': 200,
      'font-style': 'normal',
      fallbacks: [{ src: `url(${trimUrl(UHCSansLight)})` }],
      src: [`url(${trimUrl(UHCSansLight)}) format('woff')`],
    },
    {
      'font-family': 'UHC Sans',
      'font-weight': 200,
      'font-style': 'italic',
      fallbacks: [{ src: `url(${trimUrl(UHCSansLightItalic)})` }],
      src: [`url(${trimUrl(UHCSansLightItalic)}) format('woff')`],
    },
    {
      'font-family': 'UHC Sans',
      'font-weight': 'normal',
      'font-style': 'normal',
      fallbacks: [{ src: `url(${trimUrl(UHCSansRegular)})` }],
      src: [`url(${trimUrl(UHCSansRegular)}) format('woff')`],
    },
    {
      'font-family': 'UHC Sans',
      'font-weight': 'normal',
      'font-style': 'italic',
      fallbacks: [{ src: `url(${trimUrl(UHCSansItalic)})` }],
      src: [`url(${trimUrl(UHCSansItalic)}) format('woff')`],
    },
    {
      'font-family': 'UHC Sans',
      'font-weight': 600,
      'font-style': 'normal',
      fallbacks: [{ src: `url(${trimUrl(UHCSansSemiBold)})` }],
      src: [`url(${trimUrl(UHCSansSemiBold)}) format('woff')`],
    },
    {
      'font-family': 'UHC Sans',
      'font-weight': 800,
      'font-style': 'normal',
      fallbacks: [{ src: `url(${trimUrl(UHCSansBold)})` }],
      src: [`url(${trimUrl(UHCSansBold)}) format('woff')`],
    },
  ],
};
