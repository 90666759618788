import React from 'react';
import { config } from '@abyss/web/tools/config';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { IconComment } from '@hhs/ui/src/base/Icon/Material/IconComment';
import { baseStyles } from '../SideNav.styles';

export const SideNavSurvey = () => {
  const classes = useStyles(baseStyles, null, null);
  return (
    <li className={classes.tab}>
      <div className={classes.feedback} role="navigation">
        <a
          href={config('FEEDBACK_URL')}
          target="_blank"
          tabIndex="0"
          rel="noopener noreferrer"
          aria-label="click to submit feedback"
          className={classes.feedbackLink}
        >
          <IconComment size={14} color='inherit' className={classes.feedbackIcon} />
          Feedback
        </a>
      </div>
    </li>
  );
};
