const getPageData = () => {
  return {
    pageName: '',
    siteSectionL1: 'cares- uninsured payment program',
  };
};

const getUserData = data => {
  return {
    uuid: data.uuid || '',
    tin: data.providerTin || '',
    memberid: data.memberid || '',
    mpin: data.mpin || '',
  };
};

export const adobeTags = {
  LANDING_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: data.pageName || '',
          siteSectionL1: 'hhs-dental process payment program ',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  FOOTER_NAVIGATION_LINK_TRACK: data => {
    return {
      event: 'linkTrack',
      data: {
        content: {
          ...getPageData(),
          pageName: 'landing page',
          siteSectionL1: 'hhs-dental process payment program',
        },
        actions: {
          linkName: data.linkName || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
              acceptedTIN: data.acceptedTIN || '',
              nonacceptedTIN: data.nonacceptedTIN || '',
            },
          },
        },
      },
    };
  },
  TIN_VALIDATION_DO_NOT_ACCEPT: data => {
    return {
      event: 'errorTracking',
      data: {
        content: {
          ...getPageData(),
          pageName: 'TIN Validation Acknowledgment',
          siteSectionL1: 'cares- uninsured payment program',
          errorCode: '',
          errorType: 'business validation error',
          errorFields: '',
          errorMessage: data.errorMessage,
          digitalData: {
            protected: {
              user: {
                ...getUserData(data),
              },
            },
          },
        },
      },
    };
  },
  API_ERROR: data => {
    return {
      event: 'errorTracking',
      data: {
        content: {
          ...getPageData(),
          pageName: data.pageName,
          errorCode: data.errorCode,
          errorType: data.errorType,
          errorFields: '',
          errorMessage: data.errorMessage,
          digitalData: {
            protected: {
              user: {
                ...getUserData(data),
              },
            },
          },
        },
      },
    };
  },
  PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: data.pageName,
          siteSectionL2: data.siteSectionL2,
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
              billingDashboardTin: data.billingDashboardTin || '',
            },
          },
        },
      },
    };
  },
  BUTTON_CLICK: data => {
    return {
      event: 'buttonClicks',
      data: {
        content: {
          ...getPageData(),
          pageName: data.pageName,
          siteSectionL1: ' hhs-dental process payment program',
          siteSectionL2: data.siteSectionL2 || '',
        },
        actions: {
          linkName: data.linkName,
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  LINK_CLICK: data => {
    return {
      event: 'linkClicks',
      data: {
        content: {
          ...getPageData(),
          pageName: data.pageName,
          siteSectionL1: ' hhs-dental process payment program',
          siteSectionL2: data.siteSectionL2 || '',
        },
        actions: {
          linkName: data.linkName,
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
};
