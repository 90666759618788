export const baseStyles = theme => {
  return {
    navBar: {
      position: 'fixed',
      right: 0,
      top: '170px',
      zIndex: 101,
      width: '40px',
      textAlign: 'center',
      '&:a': {
        textDecoration: 'none',
      },
    },
    tabs: {
      position: 'relative',
      width: '340px',
      top: '240px',
      fontSize: '12px',
    },
    tabList: {
      position: 'absolute !important',
      margin: 0,
      padding: 0,
      listStyle: 'none',
      transformOrigin: '0 0',
      transform: 'rotate(-90deg) translate(0, 10px)',
    },
    tab: {
      float: 'right',
      padding: '2px 0',
      marginRight: '5px',
      backgroundColor: '#fff',
      lineHeight: 1,
      border: `2px solid ${'#005eaa !important'}`,
      borderBottom: 0,
      borderRadius: '5px 5px 0 0',
      color: '#005eaa !important',
      fill: '#005eaa',

      '&:hover': {
        backgroundColor: '#005eaa !important',
        color: '#fff !important',
        fill: '#fff !important',
      },
    },
    feedback: {
      width: 'auto',
    },
    feedbackLink: {
      color: 'inherit',
      cursor: 'pointer',
      display: 'block',
      fontWeight: 900,
      padding: '5px 15px',

      '&:hover': {
        color: '#fff !important',
        textDecoration: 'none',
      },
    },
    feedbackIcon: {
      margin: '0 5px 0 0',
      verticalAlign: 'top',
      '&:hover': {
        color: '#fff',
      },
    },
    chat: {
      width: '65px',
    },
    chatLink: {
      textAlign: 'right',
      color: '#fff',
      padding: '5px 10px 5px 5px',
      display: 'block',
      fontWeight: 200,
      cursor: 'pointer',
    },
    chatIcon: {
      position: 'absolute',
      left: '10px',
      top: '8px',
      width: '14px',
    },
  };
};
