export const styles = theme => {
  return {
    footer: {
      paddingTop: 30,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: '#d8d8d8',
    },
    links: {
      maxWidth: 800,
    },
    textBold: {
      fontWeight: 'bold',
      fontSize: '12px',
      '& h2': {
        fontSize: '15px',
        fontWeight: 'bold',
      },
    },
    textColor: {
      fontWeight: 'bold',
      fontSize: '12px',
      color: '#005eaa !important',
    },
    copyRight: {
      marginTop: 20,
      fontSize: '12px',
      color: 'black',
    },
    logos: {
      backgroundColor: '#ffffff',
      paddingTop: 20,
      paddingBottom: 20,
      borderTopWidth: 1,
      borderStyle: 'solid',
      borderColor: '#d8d8d8',
      marginTop: 30,
    },
    logoImage: {
      height: 60,
    },
    visuallyHide: {
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    },
    footer_links: {
      fontSize: '22px',
      marginTop: '5px',
      marginRight: '60px',
      marginLeft: '10px',
      maxWidth: '243px',
    },
    burdenStatement: {
      marginTop: 20,
      fontSize: '12px',
      color: 'black',
      textAlign: 'justify',
    },
  };
};
