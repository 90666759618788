import React from 'react';
import { Flex } from '@abyss/web/ui/Flex';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { Card } from '@abyss/web/ui/Card';
import { event } from '@abyss/web/tools/event';
import { config } from '@abyss/web/tools/config';
import { getFeature } from '@hhs/api/src/tools/features';
import { styles } from '../LandingPage.styles';

export const Tab2 = () => {
  const classes = useStyles(styles);
  const signInAs = getFeature('OIDC_MIGRATION')
    ? 'One Healthcare ID'
    : 'Optum ID';
  const handleRegistration = () => {
    event('LINK_CLICK', {
      pageName: 'care unauthenticated page- resources and support',
      linkName: 'start registration here',
    });
  };
  const handleSignIn = () => {
    event('LINK_CLICK', {
      pageName: 'care unauthenticated page- resources and support',
      linkName: `sign in with ${signInAs} here`,
    });
  };
  return (
    <React.Fragment>
      <div className={classes.textContent}>
        <p>
          The Department of Health and Human Services has contracted with
          UnitedHealth Group to administrator Provider Relief Fund payments.
          Therefore, some steps in the process involve existing UnitedHealth
          Group tools. Specifically, you'll need to set up an {signInAs} in
          order to access the portal. The process will not involve credentialing
          or contracting with UnitedHealth Group, and the information you submit
          will be used to administer the Provider Relief Fund payment.
        </p>
      </div>
      <Card className={classes.tab2Card}>
        <Card.Section>
          <Flex>
            <Flex.Content>
              <div className={classes.leadLine}>Set up {signInAs}</div>
              <br />
              <div>
                <p className={classes.leadLine}>
                  1. If you <b>do not </b> have an {signInAs}
                </p>
                You will need to create an {signInAs} to access the portal,{' '}
                <a
                  onClick={handleRegistration}
                  href={config('OHCI_REG_URL')}
                  /* eslint-disable react/jsx-no-target-blank */
                  target="_blank"
                >
                  start registration here
                </a>{' '}
                to begin.
              </div>
              <br />
              <br />
              <br />
              <div>
                <p className={classes.leadLine}>
                  2. If you <b>have </b> an {signInAs} already
                </p>
                You can access the portal at the top right of the webpage to
                sign in or{' '}
                <a onClick={handleSignIn} href={config('DASHBOARD')}>
                  sign in with {signInAs} here.
                </a>
              </div>
            </Flex.Content>
            <Flex.Content space-self>
              <div className={classes.logingImgwrapper}>
                <img
                  className={classes.banner.OptumLogin}
                  src={
                    getFeature('OIDC_MIGRATION') ? 'images/createOptumID.png' : 'images/optumLogin.png'
                  }
                  alt="Powered by UnitedHealth Group Logo"
                />
                <img
                  className={classes.buttonImage}
                  src={'images/buttonImage.png'}
                  alt="Powered by UnitedHealth Group Logo"
                />
              </div>
            </Flex.Content>
          </Flex>
        </Card.Section>
      </Card>
    </React.Fragment>
  );
};
