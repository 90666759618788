export const styles = theme => {
  return {
    heading: {
      fontWeight: 600,
    },
    bodyContent: {
      width: '71rem',
      marginBottom: '20px',
    },
    bodyHeaderContent: {
      width: '110%',
      marginBottom: '20px',
      height: '2.5rem',
      fontWeight: 'bold',
      backgroundColor: 'rgb(217,217,217)',
      '& div': { position: 'relative', top: '0.5rem', left: '0.5rem' },
    },
    fullWidth: {
      width: '109%',
    },
    aTag: {
      fontFamily: 'Bold, UHC Sans',
      fontWeight: 700,
      textDecoration: 'none !important',
      cursor: 'pointer',
      padding: '30px',
      margin: '0 -20px',
      color: '#2964c7',
      '&:hover': {
        borderBottom: '3px solid #2964c7',
      },
    },
    subWrap: {
      display: 'inline-grid',
      margin: '5% 0 0 0',
      maxWidth: 855,
      minWidth: 600,
    },
    textHeading: {
      color: '#002677',
      fontFamily: 'Semibold, UHC Sans',
      fontSize: '48px',
      lineHeight: '1.2',
    },
    textSubHeading: {
      color: '#002677',
      fontFamily: 'Medium, UHC Sans',
      fontSize: '30px',
      lineHeight: '1.2',
    },
    logingImgwrapper: { width: '278px' },
    banner: { maxHeight: '364px !important', width: '100%' },
    headingWrap: {
      marginBottom: '-8px',
      height: '365px',
      backgroundColor: '#E5F8FB',
      position: 'relative',
    },
    aTag2: {
      fontFamily: 'Bold, UHC Sans',
      fontWeight: 700,
      textDecoration: 'none !important',
      cursor: 'pointer',
      padding: '30px',
      margin: '0 -20px',
      color: 'black',
      backgroundColor: '#f8fcff',
      borderBottom: '3px solid #2964c7',
    },

    button: {
      fontSize: '13px',
      fontWeight: 'normal',
      margin: '10px 20px',
      width: '132px',
    },
    addButton: {
      margin: '20px 0',
      width: '20%',
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headline: {
      fontSize: '20px',
      marginBottom: '7px',
    },

    heading1: {
      marginTop: 30,
    },
    cardBody: { display: 'flex', flexDirection: 'column' },
    taxIdCard: {
      margin: '20px 0',
    },

    cardContent: {
      minHeight: 220,
    },
    button2: {
      fontSize: '13px',
      fontWeight: 'normal',
      margin: '10px 20px',
      width: '132px',
      marginLeft: '4px',
    },
    tab2Card: {
      border: '2px solid #688dcc !important',
      marginBottom: '20px',
      padding: '38px',
    },
    bulletPoint: {
      marginLeft: '21px',
      listStyleType: 'circle',
    },
    leadLine: { fontSize: '24px', fontWeight: 'bold' },
    tab3Card: { border: '2px solid #688dcc !important' },
    bodyClassName: {
      margin: '0 15px',
    },
    cardBodyTab3: { padding: '50px !important' },
    iconBox: {
      textAlign: 'center',
      margin: '40% 10%',
    },
    stepHeading: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 1,
    },

    anotherTinHead: {
      margin: '20px 0px',
      fontSize: '18px',
      color: '#0000007a',
    },

    textContent: {
      lineHeight: '18px',
      fontSize: '16px',
      marginBottom: 30,
    },
    actions: {
      marginTop: '28px',
      '& button:nth-child(1)': {
        marginRight: '40px',
      },
    },
    card1: {
      height: '400px !important',
      width: '500px',
      background: 'lightgrey !important',
    },
    feedBackButton: {
      borderRadius: '1px',
      paddingLeft: '14px',
      paddingRight: '14px',
    },
    fileSuccessContainer: {
      marginLeft: 30,
      marginBottom: 12,
      fontWeight: 'bold',
    },
    horizontalLine: {
      borderBottom: `1px solid #D8D8D8`,
      marginTop: 10,
      marginBottom: 10,
    },
    pageDivider1: {
      height: '300px',
      background: 'white',
      position: 'relative',
      marginTop: '-246px',
      zIndex: '-1',
      borderTop: '2px solid #b6b6c1',
    },
    pageDivider2: {
      height: '546px',
      background: 'white',
      position: 'relative',
      marginTop: '-285px',
      zIndex: '-1',
      borderTop: '2px solid #b6b6c1',
    },
    submitSection: {
      marginTop: 45,
    },
    tinBox: {
      marginRight: 30,
    },
    ul: { listStyle: 'none', display: 'inline-block' },
    li: {
      display: 'inline-block',
      padding: '30px 20px',
      whiteSpace: 'nowrap',
    },
    pageHeader: { marginBottom: '0 !important' },
    tinBoxBold: {
      marginTop: 5,
      fontWeight: 'bold',
    },
    menuBar: { padding: '0 !important' },
    textBold: {
      fontWeight: 'bold',
    },
    messageSection: {
      lineHeight: 1.8,
    },
    errorList: {
      marginTop: 10,
      marginBottom: 10,
    },
    bold: {
      fontWeight: 'bold',
      lineHeight: '18px',
      fontSize: '18px',
      marginBottom: 10,
    },
    tab4Heading: {
      lineHeight: '18px',
      fontSize: '24px',
    },
    hyperLink: {
      color: '#005eaa !important',
      background: 'transparent',
      textDecoration: 'none',
      webkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      textSizeAdjust: '100%',
      webkitFontSmoothing: 'antialiased',
      cursor: 'pointer',
      '&:hover': {
        color: '#004379',
        textDecoration: 'underline',
        outlineWidth: 0,
      },
      '&:active': {
        color: '#004379',
        textDecoration: 'underline',
        outlineWidth: 0,
      },
    },
    visuallyHide: {
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    },
  };
};
