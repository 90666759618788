import React from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { event } from '@abyss/web/tools/event';
import { PageLayout } from '@hhs/ui/src/common/PageLayout';
import { Flex } from '@abyss/web/ui/Flex';
import { config } from '@abyss/web/tools/config';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { getFeature } from '@hhs/api/src/tools/features';
import { styles } from './Footer.styles';

export const Footer = () => {
  const classes = useStyles(styles);

  const handleNavigation = linkName => {
    event('FOOTER_NAVIGATION_LINK_TRACK', {
      linkName,
    });
  };

  return (
    <footer className={classes.footer}>
      <PageLayout>
        <nav>
          <Flex gutters className={classes.links}>
            <ul className={classes.footer_links}>
              <li>
                <div className={classes.textBold}>
                  <h2>Important Information</h2>
                </div>
              </li>
              <li>
                <a
                  href={config('CORONAVIRUS_GOV_URL')}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleNavigation('COVID-19 Uninsured Program')}
                  className={classes.textColor}
                >
                  HRSA Provider Relief Fund{' '}
                  <span className={classes.visuallyHide}>
                    Opens in a new window
                  </span>{' '}
                  <IconMaterial size={12} alt="" icon="launch"/>
                </a>
              </li>
              <li>
                <a
                  href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    handleNavigation('Vulnerability Disclosure Policy')
                  }
                  className={classes.textColor}
                >
                  Vulnerability Disclosure Policy{' '}
                  <span className={classes.visuallyHide}>
                    Opens in a new window
                  </span>{' '}
                  <IconMaterial size={12} alt="" icon="launch"/>
                </a>
              </li>
            </ul>
            <ul className={classes.footer_links}>
              <li>
                <div className={classes.textBold}>
                  <h2>Support</h2>
                </div>
              </li>
              <li>
                <a
                  href={`${config('BASE_APP_URL')}/contact-us`}
                  onClick={() => handleNavigation('Contact Us')}
                  className={classes.textColor}
                >
                  Contact Us
                </a>
              </li>
              <li>
                <a
                  href={config('FEEDBACK_URL')}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleNavigation('Feedback')}
                  className={classes.textColor}
                >
                  Feedback{' '}
                  <span className={classes.visuallyHide}>
                    Opens in a new window
                  </span>{' '}
                  <IconMaterial size={12} alt="" icon="launch"/>
                </a>
              </li>
            </ul>
            <ul className={classes.footer_links}>
              <li>
                <div className={classes.textBold}>
                  <h2>Accessibility</h2>
                </div>
              </li>
              <li>
                <a
                  href={`${config('BASE_APP_URL')}/accessibility-statement`}
                  onClick={() => handleNavigation('Accessibility Statement')}
                  className={classes.textColor}
                >
                  Accessibility Statement
                </a>
              </li>
            </ul>
          </Flex>
        </nav>
        <div className={classes.copyRight}>
          <ul>
            <li>&copy; {getFeature('HRSA_FOOTER_COPYRIGHT_TXT')}</li>
            <li>
              <a
                href={`${config('BASE_APP_URL')}/terms-of-use`}
                onClick={() => handleNavigation('Terms Of Use')}
              >
                Terms Of Use
              </a>{' '}
              |{' '}
              <a
                href={`${config('BASE_APP_URL')}/privacy-policy`}
                onClick={() => handleNavigation('Privacy Policy')}
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div className={classes.burdenStatement}>
          OMB Number (0906-0069) Expiration date (02/28/2025)
          <p />
          Public Burden Statement: Information collected will allow for (1)
          assessing if recipients have met statutory and programmatic
          requirements, (2) conducting audits, (3) gathering data required to
          calculate, disburse, and report on PRF, ARPA-R, CAF, and UIP payments,
          and (4) program evaluation. HRSA staff will also use information
          collected to identify and report on trends of the COVID-19 pandemic on
          healthcare providers and uninsured or underinsured patients throughout
          the United States. An agency may not conduct or sponsor, and a person
          is not required to respond to, a collection of information unless it
          displays a currently valid OMB control number. The OMB control number
          for this information collection is 0906-0069 and it is valid until
          02/28/2025. This information collection is required to obtain or
          retain benefits by authority of P.L.116-136, 116-, 139, 116-260,
          116-127, and 117-2. Public reporting burden for this collection of
          information is estimated to average 15 minutes per response, including
          the time for reviewing instructions, searching existing data sources,
          and completing and reviewing the collection of information. Send
          comments regarding this burden estimate or any other aspect of this
          collection of information, including suggestions for reducing this
          burden, to HRSA Reports Clearance Officer, 5600 Fishers Lane, Room
          14N136B, Rockville, Maryland, 20857 or{' '}
          <a href="mailto:paperwork@hrsa.gov">paperwork@hrsa.gov</a>.
        </div>
      </PageLayout>
      <div className={classes.logos}>
        <PageLayout>
          <Flex gutters-horizontal justify-space-between>
            <Flex.Content space-self>
              <img
                className={classes.logoImage}
                src={'/images/uhg-logo.png'}
                alt="Powered by UnitedHealth Group Logo"
              />
            </Flex.Content>
            <Flex.Content space-self>
              <img
                style={{ marginLeft: 620 }}
                className={classes.logoImage}
                src={'/images/hrsa-logo.png'}
                alt="Health Resources and Services Administration Logo"
              />
            </Flex.Content>
          </Flex>
        </PageLayout>
      </div>
    </footer>
  );
};
