import { configureStore } from '@hhs/ui/old-abyss/ui/redux/store';
import { getDefaultMiddleware } from '@hhs/ui/old-abyss/ui/redux/middleware';
import { createSagaMiddleware } from '@hhs/ui/old-abyss/ui/redux/saga/middleware';
import { sagaReducer } from '@hhs/ui/old-abyss/ui/redux/saga/reducer';
import { combineSagas } from '@hhs/ui/old-abyss/ui/redux/helpers/combineSagas';
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { reducers, sagas } from './modules';

const createRootReducer = () => {
  return combineReducers({
    ...reducers,
    sagas: sagaReducer(sagas),
  });
};

const createRootSaga = () => {
  return function* rootSaga() {
    yield all(combineSagas(sagas));
  };
};

export const configureAppStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: createRootReducer(),
    middleware: [...getDefaultMiddleware(), sagaMiddleware],
  });

  store.sagaTask = sagaMiddleware.run(createRootSaga());

  if (module.hot) {
    module.hot.accept('./modules', () => {
      store.replaceReducer(createRootReducer());
      store.sagaTask.cancel();
      store.sagaTask = sagaMiddleware.run(createRootSaga());
    });
  }

  return store;
};
