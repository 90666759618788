import React, { useState, useEffect } from 'react';
import { Flex } from '@abyss/web/ui/Flex';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { PageLayout } from '@hhs/ui/src/common/PageLayout';
import { event } from '@abyss/web/tools/event';
import { getFeature } from '@hhs/api/src/tools/features';
import { PageHeader } from './PageHeader';
import { Tab1 } from './Tabs/Tab1';
import { Tab2 } from './Tabs/Tab2';
import { Tab3 } from './Tabs/Tab3';
import { Tab4 } from './Tabs/Tab4';
import { styles } from './LandingPage.styles';

export const LandingPage = () => {
  const classes = useStyles(styles);
  const [menuItems] = useState([
    'Overview',
    `Set Up ${getFeature('OIDC_MIGRATION') ? 'One Healthcare ID' : 'Optum ID'}`,
    'What You Need',
    'Resources and Support',
  ]);
  const [selectedItem, setSelectedItem] = useState();
  const menuMapping = {
    Overview: 'care unauthenticated landing page- overview',
    'Set Up Optum ID': 'care unauthenticated page- set up optum id',
    'What You Need': 'care unauthenticated page- what you need',
    'Resources and Support': 'care unauthenticated page- resources and support',
  };
  useEffect(() => {
    setSelectedItem('Overview');
    event('LANDING_PAGE_VIEW', {
      pageName: 'care unauthenticated landing page- overview',
    });
  }, []);

  const clickedItem = e => {
    e.preventDefault();
    const currentItem = selectedItem;
    setSelectedItem(e.target.innerText.trim());
    const pageName = menuMapping[e.target.innerText];
    const previousPage = menuMapping[currentItem];
    event('BUTTON_CLICK', {
      pageName: previousPage,
      linkName: e.target.innerText,
    });
    event('LANDING_PAGE_VIEW', {
      pageName,
    });
  };

  function menu() {
    let style = '';
    return (
      <div>
        <ul className={classes.ul}>
          {menuItems.map(item => {
            if (selectedItem === item) {
              style = classes.aTag2;
            } else {
              style = classes.aTag;
            }
            return (
              <li key={item} className={classes.li}>
                <a href="1" className={style} onClick={clickedItem}>
                  {' '}
                  {item}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.headingWrap}>
        <PageLayout>
          <span className={classes.subWrap}>
            <b>
              <span className={classes.textHeading}>
                Welcome to the Provider Relief Fund Application and Attestation
                Portal
              </span>
            </b>
            <br />
            <span className={classes.textSubHeading}>
              This portal allows providers to apply for and attest to relief
              fund payments made for healthcare-related expenses or lost revenue
              attributable to COVID-19.
            </span>
          </span>
        </PageLayout>
      </div>
      <PageHeader showBackButton={false} className={classes.menuBar}>
        <Flex>
          <Flex.Content>{menu()}</Flex.Content>
        </Flex>
      </PageHeader>
      <PageLayout>
        <When condition={selectedItem === 'Overview'}>
          <Tab1 />
        </When>
        <When
          condition={
            selectedItem ===
            `Set Up ${
              getFeature('OIDC_MIGRATION') ? 'One Healthcare ID' : 'Optum ID'
            }`
          }
        >
          <Tab2 />
        </When>
        <When condition={selectedItem === 'What You Need'}>
          <Tab3 />
        </When>
        <When condition={selectedItem === 'Resources and Support'}>
          <Tab4 />
        </When>
      </PageLayout>
      {selectedItem === 'What You Need' ||
      selectedItem === 'Set Up Optum ID' ? (
        <div
          className={
            selectedItem === 'What You Need'
              ? classes.pageDivider2
              : classes.pageDivider1
          }
        />
      ) : null}
    </React.Fragment>
  );
};
