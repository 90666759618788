import { config } from '@abyss/web/tools/config';

export const honeypot = () => {
  const domain = document.domain.toLowerCase();
  const parts = domain.split('.');

  if (parts.length >= 2) {
    let isMatching = false;

    if (`${parts[1]}.${parts[2]}` === 'linkhealth.com') {
      isMatching = true;
    } else if (domain === 'd3h8piho2asfxu.cloudfront.net') {
      isMatching = true;
    }

    if (!isMatching) {
      const healthUrl = config('HEALTH_URL');
      const healthCanary = config('HEALTH_CANARY');
      const l = encodeURI(window.location.href);
      const r = encodeURI(document.referrer);
      const m = new Image();
      m.src = `${healthUrl}/${healthCanary}.jpg?l=${l}&amp;r=${r}`;
    }
  }
};
