import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/web/tools/classNames';
import { PageLayout } from '@hhs/ui/src/common/PageLayout';

import { baseStyles } from './PageHeader.styles';

export const PageHeader = ({
  className,
  styles,
  children,
  showBackButton,
  ...props
}) => {
  const classes = useStyles(baseStyles, null, styles);

  return (
    <div {...props} className={classNames(classes.pageHeader, className)}>
      <PageLayout isWide>
        <If condition={showBackButton}>
          <Then>
            <button
              type="button"
              className={classes.backButton}
              aria-label="Back"
            />
          </Then>
        </If>
        <PageLayout style={{ marginLeft: showBackButton ? 90 : 'auto' }}>
          {children}
        </PageLayout>
      </PageLayout>
    </div>
  );
};

PageHeader.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  children: PropTypes.node,
  showBackButton: PropTypes.bool,
};

PageHeader.defaultProps = {
  className: null,
  styles: null,
  children: null,
  showBackButton: true,
};
