export const styles = () => {
  return {
    header: {
      backgroundColor: '#ffffff',
      minHeight: 110,
      borderBottomWidth: 6,
      borderBottomStyle: 'solid',
      borderBottomColor: '#005eaa !important',
    },
    topBar: {
      borderBottom: '1px solid #CBCBCB',
    },
    timelineTitle: {
      fontWeight: 'bold',
      marginBottom: 4,
    },
    skipLink: {
      position: 'absolute',
      left: '-10000px',
      top: 'auto',
      width: '1px',
      height: '1px',
      overflow: 'hidden',
      '&:focus': {
        width: 'auto',
        height: 'auto',
        left: 'auto',
        right: 'auto',
        top: 'auto',
        position: 'inherit',
      },
    },
    flexHeader: {
      padding: '10px 0 5px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    myAccountIcon: {
      marginTop: -3,
    },
    myAccountText: {
      marginLeft: 5,
      marginRight: 10,
      color: '#005eaa !important',
    },
    dropdownButton: {
      margin: '5px 0',
      display: 'flex',
      alignItems: 'center',

      '&:hover $myAccountText, &:active $myAccountText': {
        color: '#196ECF',
      },
    },
    dropdownContent: {
      right: 0,
      width: 130,
      backgroundColor: '#ffffff',
      boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.5)',
      textAlign: 'left',
      padding: '10px',
      fontSize: 14,
    },
    dropDownItem: {
      paddingTop: 5,

      '&:first-child': {
        paddingTop: 0,
      },
    },
    hyperLink: {
      color: '#005eaa !important',
      background: 'transparent',
      textDecoration: 'none',
      webkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      textSizeAdjust: '100%',
      webkitFontSmoothing: 'antialiased',
      cursor: 'pointer',
      '&:hover': {
        color: '#004379',
        textDecoration: 'underline',
        outlineWidth: 0,
      },
      '&:active': {
        color: '#004379',
        textDecoration: 'underline',
        outlineWidth: 0,
      },
    },
    button: { 
      padding: '0px 36px',
      borderRadius: 3,
      color: '#fff !important',
    },
    logo: {
      maxWidth: 225,
    }
  };
};
