import React from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { SideNavSurvey } from './SideNavSurvey';
import { baseStyles } from './SideNav.styles';

export const SideNav = () => {
  const classes = useStyles(baseStyles, null, null);
  return (
    <div className={classes.navBar}>
      <div className={classes.tabs}>
        <ul className={classes.tabList}>
          <SideNavSurvey />
        </ul>
      </div>
    </div>
  );
};
