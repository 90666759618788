import { NEWRELIC_MAX_PAYLOADSTRING_SIZE } from './constant';

export const newRelicTags = {
  API_TRANSACTION: data => {
    let payload;
    let body;
    let amzn;

    if (data.payload) {
      payload = JSON.stringify(data.payload);
    }

    if (data.result === 'error') {
      body = JSON.stringify(data.body || '').substring(0, 100);

      if (data.headers) {
        const amzHeaders = {};
        Object.keys(data.headers).forEach(key => {
          if (key.startsWith('x-amz')) {
            amzHeaders[key] = data.headers[key];
          }
        });
        if (Object.keys(amzHeaders).length) {
          amzn = JSON.stringify(amzHeaders);
        }
      }
    }

    return {
      event: 'api-transaction',
      data: {
        ipAddress: data.ipAddress,
        uuid: data.uuid,
        optumId: data.optumId,
        result: data.result,
        duration: data.duration,
        status: data.status,
        endpoint: data.endpoint,
        requestId: data.requestId,
        code: data.code,
        message: data.message,
        payload:
          payload && payload.length >= NEWRELIC_MAX_PAYLOADSTRING_SIZE
            ? 'Payload is too large'
            : payload,
        body,
        amzn,
      },
    };
  },
  JAVASCRIPT_ERROR: data => {
    return {
      event: 'javascript-error',
      data: {
        ipAddress: data.ipAddress,
        message: data.message,
        stack: data.stack,
      },
    };
  },
  VALIDATE_TINS_ERROR: data => {
    return {
      event: 'alert-error',
      data: {
        requestId: 'validate-tins',
        errorCode: data.errorCode,
        errorType: data.errorType,
        errorMessage: data.errorMessage,
      },
    };
  },
  CONFIRM_TINS_ERROR: data => {
    return {
      event: 'alert-error',
      data: {
        requestId: 'confirm-tins',
        errorCode: data.errorCode,
        errorType: data.errorType,
        errorMessage: data.errorMessage,
      },
    };
  },
  SUBMIT_ATTESTATION_ERROR: data => {
    return {
      event: 'alert-error',
      data: {
        requestId: 'submit-attestation',
        errorCode: data.errorCode,
        errorType: data.errorType,
        errorMessage: data.errorMessage,
      },
    };
  },
  BILLING_ENTITY_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'billing entity confirmation',
      },
    };
  },
  BILLING_TINS_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'billing tins',
      },
    };
  },
  MEDICARE_VERIFICATION_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'medicare verification',
      },
    };
  },
  ATTESTATIONS_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'attestations',
      },
    };
  },
  ATTESTATION_COMPLETE_PAGE_VIEW: data => {
    return {
      event: 'page-view',
      data: {
        pageName: 'attestation complete confirmation',
        referenceNo: data.referenceNo,
        acceptedTIN: data.acceptedTIN,
        nonacceptedTIN: data.nonacceptedTIN,
        nonstimulusTIN: data.nonstimulusTIN,
        visitOptumTIN: data.visitOptumTIN,
      },
    };
  },
  NOT_ELIGIBLE_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'not eligible now',
      },
    };
  },
  DO_NOT_ACCEPT_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'do not accept funds',
      },
    };
  },
  ACCEPT_CONFIRMATION_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'attest confirmation',
      },
    };
  },
  BILLING_ENTITY_CONTINUE: data => {
    return {
      event: 'page-action',
      data: {
        pageName: 'billing entity confirmation',
        linkName: data.linkName,
      },
    };
  },
  BILLING_TINS_CONTINUE: data => {
    return {
      event: 'page-action',
      data: {
        pageName: 'billing tins',
        linkName: 'billing tax id-continue',
        billingTinEntered: data.billingTINs,
      },
    };
  },
  MEDICARE_VERIFICATION_CONTINUE: () => {
    return {
      event: 'page-action',
      data: {
        pageName: 'medicare verification',
        linkName: 'verify payment information-continue',
      },
    };
  },
  ACCEPT_CONFIRMATION_SUBMISSION: data => {
    return {
      event: 'page-action',
      data: {
        pageName: 'attest confirmation',
        linkName: data.linkName,
        serviceAddressState: data.serviceAddressState,
        serviceAddressZip: data.serviceAddressZip,
        billingAddressState: data.billingAddressState,
        billingAddressZip: data.billingAddressZip,
      },
    };
  },
  REJECT_CONFIRMATION_SUBMISSION: data => {
    return {
      event: 'page-action',
      data: {
        pageName: 'do not accept funds',
        linkName: 'I do not accept-submit',
        accountType: data.accountType,
      },
    };
  },
  VISIT_OPTUM_BANK_BUTTON: () => {
    return {
      event: 'page-action',
      data: {
        pageName: 'attestation complete confirmation',
        linkName: 'visit optum bank',
      },
    };
  },
  ATTESTATIONS_SUBMISSION: data => {
    return {
      event: 'page-action',
      data: {
        pageName: 'attestations',
        linkName: 'review and accept',
        acceptedTIN: data.acceptedTIN,
        nonacceptedTIN: data.nonacceptedTIN,
      },
    };
  },
  FAQ_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'frequently asked questions',
      },
    };
  },
  REGISTRATION_TYPE_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'w12-registration type',
      },
    };
  },
  ADDRESS_DETAILS_PAGE_VIEW: () => {
    return {
      event: 'page-view',
      data: {
        pageName: 'address details',
      },
    };
  },
};
