import React from 'react';
import { SideNav } from '@hhs/ui/src/common/SideNav';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { honeypot } from 'src/tools/honeypot';
import { registerAnalytics } from 'src/tools/analytics';
import { attachStylesheet } from '../../styles/stylesheet';

export const App = () => {
  registerAnalytics();
  honeypot();
  attachStylesheet();
  
  return (
    <React.Fragment>
      <Header />
      <SideNav />
      <Content />
      <Footer />
    </React.Fragment>
  );
};
