/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';


import { styles } from '../LandingPage.styles';

export const Tab1 = () => {
  const classes = useStyles(styles);

  return (
    <React.Fragment>
      <div className={classes.textContent}>
        <p>
          The Department of Health and Human Services (HHS) has announced $178
          billion in relief funds, including to hospitals and other healthcare
          providers on the front lines of the coronavirus response. This funding
          supports healthcare-related expenses or lost revenue attributable to
          COVID-19. This site is open to all providers who want to apply for a
          Provider Relief Fund payment, regardless of network affiliation or
          payer contracts. HHS is contracting with UnitedHealth Group to
          facilitate delivery of the funds.
        </p>
        <p>
          HHS has made publicly available the names of payment recipients and
          the amounts received, for all providers who attest to receipt of a
          payment and acceptance of the{' '}
          <a
            href="https://www.hrsa.gov/provider-relief/past-payments/terms-conditions"
            target="_blank"
            className={classes.accessiblityLink}
          >
            Terms and Conditions
          </a>{' '}
          or who retain payments for more than 90 days and are deemed to have
          accepted the{' '}
          <a
            href="https://www.hrsa.gov/provider-relief/past-payments/terms-conditions"
            target="_blank"
            className={classes.accessiblityLink}
          >
            Terms and Conditions.
          </a>{' '}
          By accepting funds, the recipient consents to the Department of Health
          and Human Services publicly disclosing the payments that recipient has
          received from the Provider Relief Fund.
        </p>
      </div>
    </React.Fragment>
  );
};
